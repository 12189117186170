<template>
    <v-dialog
        ref="dialog"
        v-model="showDialog"
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="FormattedDate"
            :label="placeholder"
            readonly
            flat
            :solo-inverted="inverted"
            :solo="!inverted"
            :outlined="outlined"
            :dense="dense"
            :rules="rules"
            :error-messages="errorMessages"
            v-bind="attrs"
            v-on="on"
            :clearable="clearable"
            @click:clear="ClearValue"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="workingDate"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="showDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="Save"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
</template>

<script>
//import { requiredLookup } from '@/datahelpers/validation'
import { parseJSON, format } from 'date-fns'


export default {
    props: {
        value: {
            required: true,
        },
        clearable: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "Pick date"
        },
        "errorMessages": {
            type: Array,
            default: function() {
                return [];
            }
        },
        rules: {
            type: Array,
            default: function() {
                return [];
            }
        },
        dense: {
            type: Boolean,
            default: false
        },
        inverted: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            showDialog: false,
            workingDate: (this.value ? format(new Date(this.value), "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd"))
        };
    },
    computed: {
        FormattedDate() {
          if (this.value) {
            return format(new Date(this.value), "dd-MM-yyyy");
          } return "";
        }
    },
    methods: {
        ClearValue(e) {
          this.workingDate = null;
          this.Save(e);
        },
        Save(e) {
            this.$emit("input", this.workingDate);
            this.showDialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
